import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from "react";
import Header from "../../components/Common/header/header";
import { getAllBlog } from "../../services/blog.service";
import { Skeleton } from "antd";
import Footer from "../../components/Common/footer";
import IslandCardAbout from "../../components/Common/aboutWhykids";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { EffectCoverflow, Navigation, FreeMode } from "swiper/modules";
// import BackgroundImage from "../../assets/images/about_whykids/background.png";

const AboutWhyKids: React.FC = () => {
  //   const swiper = useSwiper();
  //   const swiperRef = useRef<any>(null);
  const backgroundImageUrl =
    process.env.PUBLIC_URL + "/about_whykids/background.png";

  const islandsData = [
    {
      imgPath: "/about_whykids/card/tiengviet.png",
      islandName: "Tiếng Việt",
      description:
        "Tại Đảo Tiếng Việt, Whykids trang bị cho trẻ khả năng nhận diện chữ cái, rèn luyện kỹ năng viết và phát âm chuẩn. Với những kiến thức này, trẻ không chỉ có thể giao tiếp tốt hơn mà còn tự tin bước vào hành trình học tập trong tương lai.",
      listSubject: [
        "Nhận diện chữ cái",
        "Nhận diện dấu",
        "Nhận diện vần",
        "Nhận diện phụ âm ghép",
        "Nhận biết cách tạo tiếng",
      ],
      bgColorSubject: "#FFBC42",
    },
    {
      imgPath: "/about_whykids/card/toan.png",
      islandName: "Toán",
      description:
        "Tại Đảo Toán, Whykids mở ra cho trẻ một thế giới sắc màu với các con số và phép toán thú vị, giúp phát triển tư duy và khả năng quan sát. Từ đó trang bị cho trẻ những kỹ năng cần thiết, tạo nền tảng vững chắc cho hành trình học tập sau này.",
      listSubject: [
        "Nhận biết số",
        "Tập đếm số",
        "Phép tính cơ bản",
        "So sánh",
        "Nhận biết ngày, giờ",
        "Định hướng không gian",
      ],
      bgColorSubject: "#65AFFF",
    },
    {
      imgPath: "/about_whykids/card/vhdg.png",
      islandName: "Văn hoá dân gian",
      description:
        "Tại Đảo Văn Hóa, Whykids dẫn dắt trẻ khám phá những giá trị văn hóa độc đáo của Việt Nam. Trẻ sẽ không chỉ hiểu biết về quê hương mà còn hình thành lòng tự hào và gắn kết với nguồn cội ngay từ những năm tháng đầu đời.",
      listSubject: [
        "Lễ hội truyền thống văn hóa Việt Nam",
        "Trò chơi dân gian",
        "Ngày lễ và sự kiện đặc biệt",
      ],
      bgColorSubject: "#9FD356",
    },
    {
      imgPath: "/about_whykids/card/knxh.png",
      islandName: " KĨ NĂNG XÃ HỘI",
      description:
        "Tại Đảo Kỹ Năng, Whykids trang bị cho trẻ những kỹ năng thiết yếu để tự tin và vững vàng trong cuộc sống. Trẻ sẽ được chuẩn bị tốt hơn cho những tình huống hàng ngày, giúp xây dựng nền tảng vững chắc cho tương lai.",
      listSubject: [
        "Kỹ năng an toàn",
        "Kỹ năng giao tiếp, ứng xử",
        "Kiến thức đời sống xã hội",
        "Kỹ năng tự lập",
      ],
      bgColorSubject: "#007DA9",
    },
    {
      imgPath: "/about_whykids/card/khoahoc.png",
      islandName: "Khoa Học",
      description:
        "Tại Đảo Khoa Học, trẻ sẽ học thông qua các thí nghiệm STEM thú vị, khơi dậy sự tò mò và khám phá. Whykids mang đến câu trả lời cho hàng triệu câu hỏi “tại sao” của trẻ về kỹ thuật, tự nhiên và sức khỏe, giúp các em hiểu biết về thế giới xung quanh.",
      listSubject: [
        "Kỹ thuật",
        "Thí nghiệm",
        "Hiện tượng tự nhiên",
        "Địa lý",
        "Động, thực vật",
        "Sức khỏe, thân thể",
      ],
      bgColorSubject: "#F16378",
    },
    {
      imgPath: "/about_whykids/card/nghethuat.png",
      islandName: "NGHỆ THUẬT",
      description:
        "Tại Đảo Nghệ Thuật, Whykids khơi dậy sự sáng tạo và tư duy hình tượng của trẻ thông qua các hoạt động nghệ thuật, giúp trẻ tự do thể hiện bản thân và nuôi dưỡng tâm hồn, từ đó thúc đẩy sự phát triển toàn diện.",
      listSubject: [
        "Họa cụ",
        "Chất liệu hội họa",
        "Nhận biết màu sắc",
        "Các định nghĩa trong âm nhạc",
        "Nghệ thuật biểu diễn",
      ],
      bgColorSubject: "#8DD4EA",
    },
  ];

  const figureCardData = [
    "/about_whykids/figure_card/bu.png",
    "/about_whykids/figure_card/dodo.png",
    "/about_whykids/figure_card/mat.png",
    "/about_whykids/figure_card/may.png",
    "/about_whykids/figure_card/tkay.png",
  ];

  return (
    <div className="bg-white w-full">
      <div className="bg-[#142548]">
        <Header />
        <div className="flex justify-center">
          <div className="w-[85%] lg:max-xl:w-[92%] dvmax:dh:w-[90%] my-16 h-[648px] flex justify-between items-end">
            <div className="w-[50%] h-full">
              <img
                src="/about_whykids/about_whykids_banner.jpeg"
                className="h-full w-full rounded-[30px] object-cover"
                alt=""
              />
            </div>
            <div className="w-[43%]">
              <div className="font-[800] uppercase text-[70px] text-white flex gap-4">
                <p>Giới thiệu</p>
                <p className="text-[#F56177]">whykids</p>
              </div>
              <div className="text-white font-[500] text-[25px] text-justify leading-[26px]">
                <p>
                  Whykids là ứng dụng giáo dục sớm dành cho trẻ em 3-7 tuổi,
                  mang đến trải nghiệm học tập hiện đại và toàn diện trong thời
                  đại chuyển đổi số. Ứng dụng cung cấp các bài học và hoạt động
                  sáng tạo về Toán, Tiếng Việt, Kỹ năng sống, Văn hóa - Dân
                  gian, Khoa học và Nghệ thuật, giúp trẻ phát triển kỹ năng mềm
                  và kiến thức học tập toàn diện.
                </p>
                <p className="mt-4">
                  Whykids không chỉ là công cụ học tập, mà còn là phương thức
                  kết nối giữa giáo viên và học sinh, phụ huynh và con cái, tạo
                  ra môi trường học tập số hóa nơi các bé có thể trải nghiệm và
                  khám phá kiến thức một cách tự nhiên, hứng thú.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="font-[800] text-[70px] uppercase text-center my-16">
        <h1 className="text-[#9FD356]">Giải Pháp Toàn Diện </h1>
        <h1 className="text-[#142548]">cho sự phát triển của trẻ </h1>
      </div>
      <div>
        <div className="relative flex w-full h-[650px] justify-center items-center">
          {/* <button
            onClick={() => {
              swiper.slidePrev();
            }}
            className="absolute left-4 z-10 bg-blue-500 text-white p-2 rounded-full shadow-md hover:bg-blue-600 transition"
          >
            Prev
          </button>
          <button
            onClick={() => {
              swiper.slidePrev();
            }}
            className="absolute right-4 z-10 bg-blue-500 text-white p-2 rounded-full shadow-md hover:bg-blue-600 transition"
          >
            Next
          </button> */}
          {/* <button
            onClick={() => swiperRef.current?.slidePrev()}
            className="absolute left-4 z-10 bg-blue-500 text-white p-2 rounded-full shadow-md hover:bg-blue-600 transition"
          >
            Prev
          </button>
          <button
            onClick={() => swiperRef.current?.slideNext()}
            className="absolute right-4 z-10 bg-blue-500 text-white p-2 rounded-full shadow-md hover:bg-blue-600 transition"
          >
            Next
          </button> */}
          <Swiper
            // onSwiper={(swiper: any) => (swiperRef.current = swiper)} // Gán instance Swiper vào ref
            className="w-[80%] h-full"
            slidesPerView={3}
            spaceBetween={40}
            freeMode={true}
            navigation={true}
            modules={[FreeMode, Navigation]}
          >
            {islandsData.map((subject, index) => (
              <SwiperSlide
                key={index}
                className="flex justify-center items-center"
              >
                <IslandCardAbout
                  imgPath={subject.imgPath}
                  islandName={subject.islandName}
                  description={subject.description}
                  listSubject={subject.listSubject}
                  colorBgSubject={subject.bgColorSubject}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className="relative">
        <div className="flex w-full justify-center">
          <div className="font-[800] uppercase text-[70px] text-center absolute top-[350px]">
            <h1 className="text-[#65AFFF]">tính năng hiện đại</h1>
            <h1 className="text-[#142548]">học vui mỗi ngày</h1>
          </div>
        </div>
        <div className="flex justify-center">
          <div
            className="absolute bottom-[400px] h-[500px] w-[700px] flex items-center justify-center cursor-pointer"
            onClick={() => {
              window.open("https://www.youtube.com/watch?v=jcgin9j16kU");
            }}
          >
            <img src="/logo/play_icon.png" alt="" />
          </div>
        </div>
        <img
          src="/about_whykids/tinh_nang_hien_dai.png"
          className="w-full"
          alt=""
        />
      </div>
      <div
        className="h-auto py-28"
        style={{
          backgroundImage: `url(${backgroundImageUrl})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "repeat",
        }}
      >
        <h1 className="uppercase text-[70px] font-[800] text-center text-[#142548]">
          vũ trụ <span className="text-[#F56179]">Whykids</span>
        </h1>
        <div className="flex flex-col justify-center items-center w-full gap-28 ">
          {figureCardData.map((imagePath, index) => (
            <img
              key={index}
              className="w-[1384px] object-contain"
              src={imagePath}
              alt={`Image ${index + 1}`}
            />
          ))}
        </div>
      </div>
      <img
        className="w-full"
        src="/about_whykids/daigiadinh_whykids.png"
        alt=""
      />
      <Footer />
    </div>
  );
};

export default AboutWhyKids;
