import axios from "axios";
import { API_URL } from "../../config";
import axiosConfigUser from ".././axiosConfigUser";

export const paymentRequestUser = async (
  packageId: string,
  vnp_ReturnUrl: string,
  refcode?: string
) => {
  try {
    const response = await axiosConfigUser({
      method: "POST",
      url: "/payment/create",
      data: {
        packageId: packageId,
        vnp_ReturnUrl: vnp_ReturnUrl,
        voucherCode: refcode,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

const axiosConfigNoAuth = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-type": "application/json",
  },
});

export const paymentRequestUserNoAuth = async (
  packageId: string,
  vnp_ReturnUrl: string,
  email?: string,
  phone?: string,
  refcode?: string
) => {
  try {
    const response = await axiosConfigNoAuth({
      method: "POST",
      url: "/payment/create",
      data: {
        packageId: packageId,
        vnp_ReturnUrl: vnp_ReturnUrl,
        email: email,
        phone: phone,
        voucherCode: refcode,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};
