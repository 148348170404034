import React from "react";
import { Blog } from "../../../pages/BlogAndNews";
import { useNavigate } from "react-router-dom";

const NewsCard = ({ blog }: { blog: Blog }) => {
  const navigate = useNavigate();

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  return (
    <div
      className="w-full h-[300px] mb-5 bg-white rounded-[30px] pv:max-lg:flex-col pv:max-lg:h-auto flex justify-between cursor-pointer"
      onClick={() => {
        navigate(`../tin-tuc/${blog.path}`, {
          state: { dataDetail: blog },
        });
      }}
    >
      <img
        src={blog.titlepic || "default-image-url.jpg"}
        alt={blog.title || "Blog image"}
        className="bg-gray-400 w-[41%] pv:max-lg:h-[100%] pv:max-lg:w-full rounded-s-[30px] pv:max-lg:rounded-t-[30px] pv:max-lg:rounded-b-none object-cover"
      />
      <div className="flex-col place-content-center pv:max-lg:w-full w-[58%] p-5">
        <p className="font-bold text-[25px] leading-[26px] text-justify">
          {blog.title || "Untitled"}{" "}
        </p>
        <p className="my-5">
          {blog.shortdescription || "No description available"}
        </p>
        <div>
          <div className="flex items-center gap-10">
            <div className="flex items-center gap-2">
              <img
                src="library_img/icon/Clock.png"
                className="w-[25px] object-contain"
                alt=""
              />
              <div>{formatDate(blog.createdAt)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewsCard;
