import axios, { AxiosError } from "axios";
import axiosConfigUser from ".././axiosConfigUser";
import { API_URL } from "../../config";

const axiosConfig = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-type": "application/json",
  },
});

export const loginUser = async (username: string) => {
  try {
    const response = await axiosConfig({
      method: "POST",
      url: "/auth/otp/login",
      data: {
        account: username,
      },
    });
    return response.data;
  } catch (error) {
    return "Fail";
  }
};

export const verifyOTPUser = async (session_id: string, otp: String) => {
  try {
    const response = await axiosConfig({
      method: "POST",
      url: "/auth/otp/verify",
      data: {
        session_id: session_id,
        otp: otp,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getAccountInformation = async () => {
  try {
    const response = await axiosConfigUser({
      method: "GET",
      url: "/account/",
    });
    return response.data;
  } catch (error) {
    return "Fail";
  }
};

export const getParentInformation = async () => {
  try {
    const response = await axiosConfigUser({
      method: "GET",
      url: "/user/parent/information/",
    });
    return response.data;
  } catch (error) {
    return "Fail";
  }
};

export const getAccountExists = async (inputKey: string) => {
  try {
    // Determine the query parameter based on input format (email or phone)
    const paramType = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(inputKey)
      ? "email"
      : "phone";
    const response = await axiosConfig({
      method: "GET",
      url: `/tools/account-check?${paramType}=${inputKey}`,
    });

    return response.status === 200 ? response.data : "Fail";
  } catch (error) {
    const err = error as AxiosError;
    return err.response?.status === 400 ? null : "Fail";
  }
};

export const getActivePurchaseAccountInfomation = async (
  accessToken: string
) => {
  try {
    const response = await axiosConfigUser({
      method: "GET",
      url: "/account/purchase",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    return "Fail";
  }
};
