import React, { useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Footer from "../../components/Common/footer";
import Header from "../../components/Common/header/header";

const PaymentProcess: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const paymentAccess = sessionStorage.getItem("paymentProcess");
  const [isCopy, setIsCopy] = useState(false);
  console.log("Dữ liệu được truyền qua state:", location.state);

  // Hàm copy dữ liệu
  const handleCopy = () => {
    if (location.state) {
      navigator.clipboard
        .writeText(location.state as string)
        .then(() => setIsCopy(true))
        .catch((err) => console.error("Copy thất bại:", err));
    }
  };

  return paymentAccess !== "true" ? (
    <Navigate to="/" state={{ from: location }} replace />
  ) : (
    <div className="bg-gray-100">
      <Header />
      <div className="min-h-[50vh] flex flex-col items-center justify-center p-9">
        <p className="text-[30px] italic text-[#142548]">
          Đang trong quá trình thanh toán...
        </p>
        <p className="text-[22px] text-[#142548] mb-5">
          Gửi link cho khách hàng của bạn nếu bạn là Affiliate
        </p>
        <div className="w-[50%] flex justify-center">
          <button
            className="bg-[#142548] font-bold text-white px-10 py-2 rounded-[30px]"
            onClick={handleCopy}
          >
            {isCopy ? "Đã Copy" : "Copy"}
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PaymentProcess;
