import AppleStoreButton from "./button/apple_store_btn";
import GoogleStoreButton from "./button/google_store_btn";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { IoIosArrowDropleftCircle } from "react-icons/io";

import React from "react";
import { Carousel } from "antd";
import Header from "../header/header";
import { useNavigate } from "react-router-dom";

const CustomNextArrow = (props: any) => {
  const { onClick } = props;
  return (
    <IoIosArrowDroprightCircle
      onClick={onClick}
      className="pv:max-md:hidden text-[70px] dvmax:max-dh:text-[120px]"
      style={{
        // fontSize: "60px",
        color: "whitesmoke",
        position: "absolute",
        right: "10px",
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: 1,
        cursor: "pointer",
        opacity: "0.2",
      }}
    />
  );
};
const handleRightClick = (event: React.MouseEvent<HTMLVideoElement>) => {
  event.preventDefault();
};

const CustomPrevArrow = (props: any) => {
  const { onClick } = props;
  return (
    <IoIosArrowDropleftCircle
      onClick={onClick}
      className="pv:max-md:hidden text-[70px] dvmax:max-dh:text-[120px]"
      style={{
        color: "whitesmoke",
        position: "absolute",
        left: "10px",
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: 1,
        cursor: "pointer",
        opacity: "0.2",
      }}
    />
  );
};

const CarouselHeader: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-[#142548]">
      <div className="absolute z-50 w-full">
        <Header />
      </div>
      <div className="flex justify-center">
        <div className="w-[85%] lg:max-xl:w-[92%] dvmax:dh:w-[90%] h-[450px] dvmax:max-dh:h-[1000px] pv:max-pvmax:h-[600px] pvmax:max-ph:h-[650px] ph:max-sm:h-[840px] sm:max-md:h-[1000px] md:max-lg:h-[700px] items-end place-content-end lg:h-[840px]">
          <Carousel
            dotPosition="bottom"
            nextArrow={<CustomNextArrow />}
            prevArrow={<CustomPrevArrow />}
            arrows
            adaptiveHeight
          >
            {/* slider 1 */}
            <div className="">
              <div className="flex pv:max-md:flex-col-reverse  pv:max-md:items-end dvmax:max-dh:h-[1000px] pv:max-pvmax:h-[520px] pvmax:max-ph:h-[600px] sm:max-md:h-[950px] ph:max-sm:h-[750px] md:max-lg:h-[600px] pv:max-md:justify-start h-[450px] lg:h-[840px] justify-around items-end lg:max-dh:pb-[80px] md:max-lg:pb-[40px] sm:max-md:pb-10 sm:max-md:px-10 lg:max-dh:pt-[100px] md:max-lg:pt-[250px] md:max-lg:px-[50px] pvmax:max-sm:pb-10 pvmax:max-sm:px-10 pv:max-sm:pb-6 pv:max-sm:px-4">
                <div className=" w-fit pv:max-md:w-full">
                  <h5 className="mb-2 text-[80px] font-[800] dvmax:max-dh:text-[100px] pv:max-md:text-center pv:max-sm:text-2xl pvmax:max-sm:text-4xl tracking-tight text-[#F56177]">
                    WhyKids
                  </h5>
                  <p className="font-[800] pv:max-md:hidden dvmax:max-dh:text-[77px] dvmax:max-dh:leading-tight text-[45px] pv:max-md:text-center pv:max-sm:text-lg pvmax:max-sm:text-2xl text-white leading-[45px]">
                    Nền tảng giáo <br />
                    dục toàn diện <br />
                    cho thế hệ mới
                  </p>
                  <p className="font-[800] md:max-dh:hidden text-[45px] pv:max-md:text-center pv:max-sm:text-lg pvmax:max-sm:text-2xl text-white leading-[45px]">
                    Nền tảng giáo dục toàn diện cho thế hệ mới
                  </p>
                  <div className="flex mt-5 w-full pv:max-md:justify-center dvmax:max-dh:gap-5">
                    <a
                      href="https://apps.apple.com/us/app/why-kids/id6479969020"
                      className="w-1/2 pr-1"
                    >
                      <img
                        className="w-full object-contain "
                        src="logo/appstore_dowload_logo.png"
                        alt=""
                      />
                    </a>
                    <a
                      className="w-1/2 pl-1"
                      href="https://play.google.com/store/apps/details?id=com.whycop.why_kid_education_v2"
                    >
                      <img
                        className="w-full object-contain "
                        src="logo/ch_play_dowload_logo.png"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="max-h-fit min-h-[30vh] w-[600px] dvmax:max-dh:w-[780px] pv:max-md:w-full pv:max-sm:h-full flex justify-end items-end">
                  <img
                    src="logo/image_header.png"
                    className="object-contain w-full"
                    style={{ objectFit: "contain" }}
                    alt=""
                  />
                </div>
              </div>
            </div>
            {/* slider 2 */}
            <div className="">
              <div className="flex justify-start  pv:max-md:flex-col-reverse relative pv:max-md:items-end dvmax:max-dh:h-[1000px] pv:max-pvmax:h-[520px] pvmax:max-ph:h-[600px] sm:max-md:h-[950px] ph:max-sm:h-[750px] md:max-lg:h-[600px] pv:max-md:justify-start h-[450px] lg:h-[840px] items-end sm:max-md:px-10 lg:max-dh:pt-[100px] md:max-lg:pt-[250px] md:max-lg:px-[50px] pvmax:max-sm:px-10 pv:max-sm:pb-6 pv:max-sm:px-4">
                <div className="absolute z-50 left-20 bottom-20 w-fit pv:max-md:w-full">
                  <p className="font-[800] pv:max-md:hidden dvmax:max-dh:text-[77px] dvmax:max-dh:leading-tight text-[45px] pv:max-md:text-center pv:max-sm:text-lg pvmax:max-sm:text-2xl text-white leading-[45px]">
                    VTV1 nói gì về
                    <br />
                    ứng dụng Why Kid
                  </p>
                  <p className="font-[800] md:max-dh:hidden text-[45px] pv:max-md:text-center pv:max-sm:text-lg pvmax:max-sm:text-2xl text-white leading-[45px]">
                    VTV1 nói gì về ứng dụng Why Kid
                  </p>
                  <div
                    onClick={() => {
                      navigate("/tin-tuc");
                    }}
                    className="flex mt-5 w-fit px-16 py-2 pv:max-md:justify-center bg-[#F56177] cursor-pointer rounded-[30px]"
                  >
                    <p className="text-[20px] font-bold text-white">Chi tiết</p>
                  </div>
                </div>
                <div className="w-full h-[100%]">
                  <video
                    id="myvideo"
                    className="object-cover rounded-t-[30px] h-[100%] w-full opacity-70"
                    controls
                    onContextMenu={handleRightClick}
                    controlsList="nodownload"
                    autoPlay
                    muted
                  >
                    <source
                      src="https://storage.googleapis.com/why_kid_static_content/WEBSITE/VTV%20VIDEO/vtv_video.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default CarouselHeader;
