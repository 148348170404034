import React from "react";

interface IslandCardAboutProps {
  imgPath?: string;
  islandName?: string;
  description?: string;
  listSubject?: string[];
  colorBgSubject?: string;
}

const IslandCardAbout: React.FC<IslandCardAboutProps> = ({
  imgPath,
  islandName,
  description,
  listSubject = [],
  colorBgSubject,
}) => {
  return (
    <div className="bg-white shadow-lg rounded-[30px] h-[638px] text-[#142548]">
      {/* Image Section */}
      <img
        src={imgPath}
        alt={`Đảo ${islandName}`}
        className="rounded-t-[30px] w-full h-[328px]"
      />
      {/* Content Section */}
      <div className="p-4">
        <div className=" text-center">
          <h2 className="text-[30px] font-[800] text-gray-800 mb-2">
            ĐẢO {islandName?.toUpperCase()}
          </h2>
          <p className="mb-4 text-[20px] leading-[20px] text-justify">
            {description}
          </p>
        </div>

        {/* Button Section */}
        <div className="flex flex-wrap gap-2 justify-center">
          {listSubject.map((subject, index) => (
            <button
              className={`px-4 py-2 rounded-full text-[14px] font-semibold`}
              style={{ backgroundColor: colorBgSubject }}
            >
              {subject}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default IslandCardAbout;
