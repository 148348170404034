import React, { useEffect, useState, useMemo } from "react";
import Header from "../../components/Common/header/header";
import { getAllBlog } from "../../services/blog.service";
import { Skeleton } from "antd";
import LargeNewsCard from "../../components/Common/blogAndNews/largeNewsCard";
import NewsCard from "../../components/Common/blogAndNews/newsCard";
import ManyPeopleReadCard from "../../components/Common/blogAndNews/manyPeopleReadCard";
import FooterBlog from "../../components/Common/blogAndNews/footerBlog";
import { Pagination } from "antd";
import Footer from "../../components/Common/footer";

export interface Blog {
  _id: string;
  title?: string;
  shortdescription?: string;
  longdescription?: string;
  detailpic?: string;
  filename?: string;
  titlepic?: string;
  isFeatured: boolean;
  createdAt: string;
  path?: string;
  updatedAt: string;
  descriptionSite?: string;
  isPopular?: boolean;
  isPublic?: boolean;
  titleSite?: string;
  view: number;
}

const BlogAndNews: React.FC = () => {
  const [blogData, setBlogData] = useState<Blog[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const itemsPerPage = 5;

  useEffect(() => {
    const fetchBlogData = async () => {
      setLoading(true);
      try {
        const response = await getAllBlog();
        setBlogData(response.data);
      } catch (error) {
        console.error("Failed to fetch blog data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogData();
    document.title =
      "Tin tức | WhyKids - Nền tảng giáo dục toàn diện cho thế hệ mới";

    return () => {
      document.title = "WhyKids - Nền tảng giáo dục toàn diện cho thế hệ mới";
    };
  }, []);

  const featuredBlogs = useMemo(
    () => blogData.filter((blog) => blog.isFeatured),
    [blogData]
  );

  const nonFeaturedBlogs = useMemo(
    () => blogData.filter((blog) => !blog.isFeatured),
    [blogData]
  );

  const currentBlogs = useMemo(
    () =>
      nonFeaturedBlogs.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      ),
    [nonFeaturedBlogs, currentPage, itemsPerPage]
  );

  const topReading = useMemo(
    () =>
      [...blogData]
        .filter((blog) => blog.isPopular && blog.isPublic)
        .sort((a, b) => b.view - a.view)
        .slice(0, 5),
    [blogData]
  );

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < Math.ceil(nonFeaturedBlogs.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handleRightClick = (event: React.MouseEvent<HTMLVideoElement>) => {
    event.preventDefault();
  };

  return (
    <div className="bg-[#F8F8F8] w-full">
      <Header searchTextChangeColor={true} />
      <div className="flex justify-center">
        <div className="w-[85%] lg:max-xl:w-[92%] dvmax:dh:w-[90%] mb-10 ">
          <div className="flex-col pv:max-xl:flex-col pv:max-xl:items-center pv:max-xl:gap-5 h-full w-full justify-between">
            <p className="text-[#122548] pv:max-md:text-[30px] pv:max-md:text-center text-[70px] pv:max-md:mb-5 mb-10 font-bold uppercase">
              blog/ tin tức
            </p>
            <div className="w-[100%] text-[#122548] rounded-[30px] pv:max-md:flex-col flex justify-between">
              <div className="w-[65%] pv:max-md:w-full">
                {loading ? (
                  <Skeleton active />
                ) : (
                  <>
                    <div className="bg-white w-full rounded-[30px] h-[600px] mb-5">
                      <video
                        id="myvideo"
                        className="object-cover rounded-t-[30px] h-[85%] w-full"
                        controls
                        onContextMenu={handleRightClick}
                        controlsList="nodownload"
                      >
                        <source
                          src="https://storage.googleapis.com/why_kid_static_content/WEBSITE/VTV%20VIDEO/vtv_video.mp4"
                          type="video/mp4"
                        />
                      </video>
                      <div className="p-5">
                        <h1 className="text-[35px] font-bold">
                          VTV1 nói gì về ứng dụng why kids
                        </h1>
                      </div>
                    </div>
                    {blogData.length <= 0 ? (
                      <div className="flex items-center gap-2">
                        <h1 className="text-[20px] text-slate-300 font italic">
                          Hiện tại chưa có Blog/ Tin tức nào. Bạn quay lại sau
                          nhé !
                        </h1>
                      </div>
                    ) : (
                      featuredBlogs.map((blog) => (
                        <LargeNewsCard key={blog._id} blog={blog} />
                      ))
                    )}
                    {currentBlogs.map((blog) => (
                      <NewsCard key={blog._id} blog={blog} />
                    ))}

                    {/* Pagination Controls */}
                    <div className="flex justify-between mt-5">
                      <button
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                        className={`px-4 py-2 bg-gray-300 rounded-lg ${
                          currentPage === 1 ? "opacity-50" : "hover:bg-gray-400"
                        }`}
                      >
                        Trước
                      </button>

                      <span className="text-lg">
                        Trang {currentPage} /{" "}
                        {Math.ceil(nonFeaturedBlogs.length / itemsPerPage)}
                      </span>

                      <button
                        onClick={handleNextPage}
                        disabled={
                          currentPage ===
                          Math.ceil(nonFeaturedBlogs.length / itemsPerPage)
                        }
                        className={`px-4 py-2 bg-gray-300 rounded-lg ${
                          currentPage ===
                          Math.ceil(nonFeaturedBlogs.length / itemsPerPage)
                            ? "opacity-50"
                            : "hover:bg-gray-400"
                        }`}
                      >
                        Sau
                      </button>
                    </div>
                  </>
                )}
              </div>
              {/* Right section (Top 5 Blogs by View Count) */}
              <div className="w-[32%] pv:max-md:w-full">
                <div className="w-full bg-white rounded-[30px]  p-10 pv:max-md:p-5">
                  <p className="font-bold text-[35px] pvmax:max-md:text-[25px]">
                    Nhiều người đọc
                  </p>
                  {topReading.length <= 0 ? (
                    <div className="flex items-center gap-2">
                      <h1 className="text-[18px] text-slate-300 font italic">
                        Hiện tại chưa có Blog/ Tin tức nhiều người đọc nào !
                      </h1>
                    </div>
                  ) : (
                    topReading.map((blog, index) => (
                      <ManyPeopleReadCard
                        key={blog._id}
                        blog={blog}
                        isLast={index === topReading.length - 1}
                      />
                    ))
                  )}
                </div>
                {/* <div className="bg-gray-600 mt-5 w-full h-[360px] rounded-[30px]" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center mb-16">
        <FooterBlog />
      </div>
      <Footer />
    </div>
  );
};

export default BlogAndNews;
